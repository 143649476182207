import { useMemo } from 'react';
import Router from 'next/router';
import { useLatestValueRef } from '@front/helper';
import { IaAvailableAction } from '@lib/ia/src/core/IaAction';
import {
  CardCornerAvatarEvent,
  CardLayoutItem,
} from '@lib/ia/src/layouts/CardLayout/types';
import { apis, ClubJoinedStatus, ClubPrivacy } from '@lib/web/apis';
import { useIaClub, useNotifications } from '@lib/web/hooks';
import { callWithToast, getTrackElementAttributes } from '@lib/web/utils';

export enum IaClubCtaAction {
  RequestToJoinClub = 'RequestToJoinClub',
  JoinClub = 'JoinClub',
  CancelRequired = 'CancelRequired',
  AcceptInvite = 'AcceptInvite',
  LeaveClub = 'LeaveClub',
  CornerAvatarMouseEnter = 'CornerAvatarMouseEnter',
  CornerAvatarMouseLeave = 'CornerAvatarMouseLeave',
  CornerAvatarBlur = 'CornerAvatarBlur',
  CreationErrorDismiss = 'CreationErrorDismiss',
}

export const getIaClubCta = (
  clubSlug: string,
  joinStatus: ClubJoinedStatus,
  privacy: ClubPrivacy
) => {
  if (joinStatus === ClubJoinedStatus.Joined) {
    return {
      type: 'link' as const,
      value: `/club/${clubSlug}/start`,
      icon: 'ActionArrowRightUp',
      text: 'Go to Club',
    };
  }
  if (joinStatus === ClubJoinedStatus.RequestedPending) {
    return {
      type: 'event' as const,
      value: IaClubCtaAction.CancelRequired,
      icon: 'ActionCloseSmall',
      text: 'Cancel Request',
      loadingText: 'Cancelling...',
    };
  }
  if (joinStatus === ClubJoinedStatus.ManagerInvitedPending) {
    return {
      type: 'event' as const,
      value: IaClubCtaAction.AcceptInvite,
      icon: 'TextEditorCheck',
      text: 'Accept Invite',
      loadingText: 'Accepting...',
      HTMLAttributes: getTrackElementAttributes({
        elementName: 'club-accept-invite-button',
      }),
    };
  }

  if (privacy === ClubPrivacy.Private) {
    return {
      type: 'event' as const,
      value: IaClubCtaAction.RequestToJoinClub,
      icon: 'ProfileLogin',
      text: 'Request to Join Club',
      loadingText: 'Requesting...',
    };
  }

  return {
    type: 'event' as const,
    value: IaClubCtaAction.JoinClub,
    icon: 'ProfileLogin',
    text: 'Join Club',
    loadingText: 'Joining...',
    HTMLAttributes: getTrackElementAttributes({
      elementName: 'club-join-button',
    }),
  };
};

export const useIaClubActions = () => {
  const { mutate: clubNotificationsMutate } = useNotifications();

  return useMemo(
    () => ({
      accept: async ({
        clubSlug,
        inviterId,
        onSuccess,
        onError,
      }: {
        clubSlug: string;
        inviterId?: string;
        onSuccess?: () => void;
        onError?: () => void;
      }) => {
        await callWithToast(
          apis.club.acceptClubInvitation({
            clubSlug,
            isReturn: true,
            inviterId: inviterId,
          }),
          {
            onSuccess: async () => {
              clubNotificationsMutate();
              onSuccess?.();
            },
            onError,
          }
        );
      },

      cancel: async ({
        clubSlug,
        onSuccess,
        onError,
      }: {
        clubSlug: string;
        onSuccess?: () => void;
        onError?: () => void;
      }) => {
        await callWithToast(apis.club.cancelJoinClub(clubSlug), {
          onSuccess: async () => {
            clubNotificationsMutate();
            onSuccess?.();
          },
          onError,
        });
      },

      request: async ({
        clubSlug,
        onSuccess,
        onError,
      }: {
        clubSlug: string;
        onSuccess?: () => void;
        onError?: () => void;
      }) => {
        await callWithToast(apis.club.requestJoinClub(clubSlug), {
          onSuccess: async () => {
            clubNotificationsMutate();
            onSuccess?.();
          },
          onError,
        });
      },

      join: async ({
        clubSlug,
        onSuccess,
        onError,
      }: {
        clubSlug: string;
        title: string;
        onSuccess?: () => void;
        onError?: () => void;
      }) => {
        await callWithToast(apis.club.joinClub(clubSlug), {
          onSuccess: async () => {
            clubNotificationsMutate();
            onSuccess?.();
          },
          onError,
        });
      },

      leave: async ({
        clubSlug,
        onSuccess,
      }: {
        clubSlug: string;
        title: string;
        onSuccess?: () => void;
        onError?: () => void;
      }) => {
        await callWithToast(apis.club.leaveClub(clubSlug), {
          onSuccess: async () => {
            clubNotificationsMutate();
            onSuccess?.();
          },
        });
      },
    }),
    [clubNotificationsMutate]
  );
};

export const useIaClubCtaAvailableActions = ({
  slug,
  onJoined,
  onCanceled,
  onAccepted,
  onRequested,
  onLeaved,
  onJoinError,
  onRequestError,
  onAcceptError,
  onCancelError,
  onCreationErrorDismiss,
  cornerAvatarActions,
}: {
  slug?: string;
  onJoined?: (target: CardLayoutItem) => void;
  onCanceled?: (target: CardLayoutItem) => void;
  onAccepted?: (target: CardLayoutItem) => void;
  onRequested?: (target: CardLayoutItem) => void;
  onLeaved?: (target: CardLayoutItem) => void;
  onJoinError?: () => void;
  onRequestError?: () => void;
  onAcceptError?: () => void;
  onCancelError?: () => void;
  onCreationErrorDismiss?: () => void;
  cornerAvatarActions?: {
    onMouseEnter?: (event: CardCornerAvatarEvent) => void;
    onMouseLeave?: (event: CardCornerAvatarEvent) => void;
    onBlur?: (event: CardCornerAvatarEvent) => void;
  };
} = {}): Record<IaClubCtaAction, IaAvailableAction> => {
  const clubSlug =
    slug || ((Router.query.slug || Router.query.clubSlug) as string);
  const { mutate: clubNotificationsMutate } = useNotifications();
  const { mutate } = useIaClub(clubSlug);
  const actions = useIaClubActions();
  const funcRef = useLatestValueRef({
    onJoined,
    onCanceled,
    onAccepted,
    onRequested,
    onLeaved,
    onRequestError,
    onJoinError,
    onAcceptError,
    onCancelError,
    onCreationErrorDismiss,
    cornerAvatarActions,
  });

  return useMemo(
    () => ({
      [IaClubCtaAction.AcceptInvite]: {
        action: async (target: CardLayoutItem) => {
          await actions.accept({
            clubSlug: target.id,
            inviterId: target.metadata?.inviterId,
            onSuccess: async () => {
              await mutate();
              funcRef.current.onAccepted?.(target);
            },
            onError: () => {
              funcRef.current.onAcceptError?.();
            },
          });
        },
      },
      [IaClubCtaAction.CancelRequired]: {
        action: async (target: CardLayoutItem) => {
          await actions.cancel({
            clubSlug: target.id,
            onSuccess: async () => {
              await mutate();
              funcRef.current.onCanceled?.(target);
            },
            onError: () => {
              funcRef.current.onCancelError?.();
            },
          });
        },
      },
      [IaClubCtaAction.RequestToJoinClub]: {
        action: async (target: CardLayoutItem) => {
          await actions.request({
            clubSlug: target.id,
            onSuccess: async () => {
              await mutate();
              funcRef.current.onRequested?.(target);
            },
            onError: () => {
              funcRef.current.onRequestError?.();
            },
          });
        },
      },
      [IaClubCtaAction.JoinClub]: {
        action: async (target: CardLayoutItem) => {
          await actions.join({
            clubSlug: target.id,
            title: target.title,
            onSuccess: async () => {
              await mutate();
              funcRef.current.onJoined?.(target);
            },
            onError: () => {
              funcRef.current.onJoinError?.();
            },
          });
        },
      },
      [IaClubCtaAction.LeaveClub]: {
        action: async (target: CardLayoutItem) => {
          await actions.leave({
            clubSlug: target.id,
            title: target.title,
            onSuccess: async () => {
              await mutate();
              funcRef.current.onLeaved?.(target);
            },
          });
        },
      },
      [IaClubCtaAction.CornerAvatarMouseEnter]: {
        action: async (target: CardCornerAvatarEvent) => {
          funcRef.current.cornerAvatarActions?.onMouseEnter?.(target);
        },
      },
      [IaClubCtaAction.CornerAvatarMouseLeave]: {
        action: async (target: CardCornerAvatarEvent) => {
          funcRef.current.cornerAvatarActions?.onMouseLeave?.(target);
        },
      },
      [IaClubCtaAction.CornerAvatarBlur]: {
        action: async (target: CardCornerAvatarEvent) => {
          funcRef.current.cornerAvatarActions?.onBlur?.(target);
        },
      },
      [IaClubCtaAction.CreationErrorDismiss]: {
        action: async () => {
          funcRef.current.onCreationErrorDismiss?.();
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clubNotificationsMutate, mutate]
  );
};
